import React from 'react';
import {Row, Col}
    from 'react-bootstrap';

function Pageundef() {
  return (
    <div>
      <br />
      <Row className='justify-content-md-center'>
        <Col xs={3}>
          <h1>Error 404</h1> 
        </Col>
        <Col xs={5}/>
      </Row>
    </div>
  )
}

export default Pageundef;